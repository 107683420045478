<template>
  <ModalBasic
    :text="$t('modalECC.text')"
    :btn-label="$t('modalECC.btnLabel')"
    @modal-open="track('viewed_ecc_info')"
  />
</template>

<script lang="ts" setup>
const { track } = useTracker()
</script>
